<template>
  <div class="location">
    <!-- header d titulo y acciones -->
    <headerLayaut subtitle="">
      <!-- <v-btn color="primary" style="border-radius: 10px" class="mr-2"
        >Crear</v-btn
      >
      <v-btn
        color="primary"
        style="border-radius: 10px"
        class="mr-2"
        @click="createEVENT"
        >Create collection Event</v-btn
      > -->
    </headerLayaut>
    <!-- contenido de la pagina -->
    <div class="body-template" v-if="eventData">
      <v-container fluid class="fill-height pr-5 screen-width">
        <v-row class="pt-3 ml-2">
          <v-col cols="6">
            <v-text-field
              style="border-radius: 10px"
              label="Latitud"
              filled
              :rules="requerid"
              required
              @keypress="isNumber($event)"
              placeholder="Latitud"
              v-model="address[0]"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              style="border-radius: 10px"
              label="Longitud"
              filled
              :rules="requerid"
              required
              v-model="address[1]"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <l-map
              @update:center="centerUpdated"
              class="fill-height"
              style="height: 300px; border-radius: 15px"
              :zoom="zoom"
              :center="address"
            >
              <l-tile-layer :url="url"></l-tile-layer>
              <l-marker
                @update:lat-lng="positionUpdated"
                :draggable="true"
                :lat-lng="address"
                :icon="getImage"
              >
                <l-tooltip>
                  Mueva el marcador a la ubicación del comercio
                </l-tooltip>
              </l-marker>
              <!-- <v-geosearch :options="geosearchOptions"></v-geosearch> -->
            </l-map>
          </v-col>

          <!-- <v-col md="12" cols="12" sm="12">
            <l-map
              class="fill-height"
              style="height: 50vh; border-radius: 15px"
              :zoom="zoom"
              :center="center"
            >
              <l-tile-layer :url="url"></l-tile-layer>
              <l-marker
                 @update:lat-lng="positionUpdated"
                :lat-lng="[center[0], center[1]]"
                :draggable="$store.state.user.type == 'sudo' ? false : false"
                :icon="getImage"
              >
                <l-popup v-if="$store.state.user.type == 'viveBusiness'">
                  {{ eventData.zone }}!
                </l-popup>

                <l-tooltip v-else>
                  Mueva el marcador a la ubicación del comercio
                </l-tooltip>
              </l-marker>
            </l-map>
          </v-col>
          <v-col v-if="$store.state.user.type == 'sudo'" cols="12" md="6">
            <h2>Latitud</h2>

            <v-text-field
              style="border-radius: 10px"
              label="Latitud"
              filled
              required
              placeholder="Latitud"
              v-model="center[0]"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col>

          <v-col v-if="$store.state.user.type == 'sudo'" cols="12" md="6">
            <h2>Longitud</h2>

            <v-text-field
              style="border-radius: 10px"
              label="Longitud"
              filled
              required
              placeholder="Longitud"
              v-model="center[1]"
              type="text"
              rounded
              dense
            ></v-text-field>
          </v-col> -->

          <v-col cols="12">
            <h2>Dirección</h2>

            <v-textarea
              name="input-7-1"
              style="border-radius: 10px"
              label="Dirección exacta del evento"
              filled
              rounded
              required
              dense
              auto-grow
              v-model="eventData.venueName"
              placeholder="Dirección exacta del evento"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="mb-12">
          <v-col cols="12">
            <v-divider></v-divider>
            <v-row justify="end">
              <v-btn
                class="save-btn"
                :loading="loading"
                @click="SaveChanges"
                color="primary"
                dark
              >
                Guardar
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="dialog" scrollable width="500px">
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="dialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          Titulo
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text> </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn color="primary" text style="border-radius: 10px"
            >cancelar</v-btn
          >
          <v-btn color="primary" style="border-radius: 10px">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";

import { mapActions, mapState } from "vuex";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LTooltip,
  LPopup,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import VGeosearch from "vue2-leaflet-geosearch";

import headerLayaut from "@/components/header-layaut.vue";

export default {
  name: "location",
  components: {
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    headerLayaut,
    LPopup,
    LTooltip,
    VGeosearch,
  },

  data() {
    return {
      show: false,
      requerid: [(v) => !!v || "Campo requerido"],
      dialog: false,
      eventData: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 0,
      address: ["14.0985252", "-87.1789062"],
      geoAddress: [],
      staticAnchor: [16, 37],
      iconSize: 32,
      icon: L.icon(this.getImage),
      snackbar: false,
      snackbarText: "",
      save: false,
      loading: false,
      prevValue: {},
    };
  },
  watch: {
    "eventData.address"(e) {
      this.address = [e.geopoint._lat, e.geopoint._long];
     // console.debug(this.address);
    },
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 5;
        }, 10);
      }
    },
  },
  computed: {
    ...mapState(["selectedEvent"]),
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    getImage() {
      return L.icon({
        iconUrl: require("@/assets/avatar-default.png"),

        shadowUrl: require("@/assets/map-marker.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
        requerid: [(v) => !!v || "Campo requerido"],
        geosearchOptions: {
          showMarker: false,
          provider: new OpenStreetMapProvider(),
          animateZoom: true,
          autoClose: true,
          searchLabel: "Buscar comercio",
        },
        address: ["14.0985252", "-87.1789062"],

        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        zoom: 27,
        center: [47.41322, -1.219482],
        priceRange: [1, 2, 3, 4],
      });
    },
  },
  methods: {
    ...mapActions([
      "_searchTerm",
      "Alert_",
      "addEventSelection",
      "visibleSearch",
    ]),
    centerUpdated(e) {
      this.address = [e.lat, e.lng];
    },
    positionUpdated(e) {
      this.address = [e.lat, e.lng];
    },
    SaveChanges() {
      this.loading = true;
      this.getGeoPoint(this.selectedEvent[`.key`]).then(() => {
        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .update({
            venueName: this.eventData.venueName,
          })
          .then(() => {
            this.Alert_({
              text: "Se ha guardado la ubicación del evento",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
            this.loading = false;
          });
      });
    },
    getGeoPoint(id) {
      return new Promise((resolve, reject) => {
        let data = {
          latitude: this.address[0],
          longitude: this.address[1],
          collection: "events",
          collectionId: id,
        };
        var httpGeneralUpdateGeoPoint = fb
          .functions()
          .httpsCallable("httpGeneralUpdateGeoPoint");
        httpGeneralUpdateGeoPoint(data)
          .then((result) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    handleSuccess() {
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Información actualizada correctamente.";
    },
    handleFail() {
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente";
    },

    getEventData() {},
  },

  mounted() {
    var getDomain = document.domain;
    var search = getDomain.search("dilo");

    if (search != -1) {
      this.iconUrl =
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-dev-data/o/vive%2Favatar-01.png?alt=media&token=0675c14f-8569-473d-a21c-d502c9bff405";
    }
    this.show = false;
    this.$store.state.visibleSearch = false;

    if (!this.selectedEvent) this.$router.push({ path: "/" });

    //// console.debug(this.eventData.address)
    setTimeout(() => {
      this.$binding(
        "eventData",
        db.collection("events").doc(this.selectedEvent[`.key`])
      );
    }, 2000);
    //
    //        // console.debug(this.center)

    this.show = true;
    this.zoom = 1;

    // this.prevValue = Object.assign({}, this.selectedEvent);
  },
};
</script>

<style lang="scss">
#mapid {
  height: 40vh;
}
.leaflet-marker-icon {
  border-radius: 100%;
  background-color: #f96921;
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}
.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}
@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }
  25% {
    left: 0;
    top: 0;
  }
}
.save-btn {
  margin-top: 20px;
}
.vue2leaflet-map {
  z-index: 1;
}
</style>
